import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { JsonPipe } from '../../pipes/json-pipe';
import { Observable } from 'rxjs';
import {
  RouterOutlet,
  RouterModule,
  ActivatedRoute,
  Router,
} from '@angular/router';
import { ActViewerService } from '../../services/act-viewer.service';
import { BreadCrumbsComponent } from '../bread-crumbs/bread-crumbs.component';
import { MatChipEvent, MatChipsModule } from '@angular/material/chips';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { GridComponent } from '../../helpers/components/grid/grid.component';
import { ILogData } from '../../../submodules/silverlight-common/lib/ts/src/interface/log.data.interface';
import { jsonDisplayModalComponent } from '../user-list/json-display-modal/json-display-modal.component';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { logEventEnum } from '../../../submodules/silverlight-common/lib/ts/src/common-enums/log.event.enums';
@Component({
  selector: 'app-activity-viewer',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterModule,
    CommonModule,
    JsonPipe,
    BreadCrumbsComponent,
    MatToolbarModule,
    GridComponent,
    MatChipsModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
  ],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          transform: 'translateX(0)',
          opacity: 1,
        })
      ),

      state(
        'out',
        style({
          transform: 'translateX(100%)',
          opacity: 0,
        })
      ),
      transition('in => out', animate('300ms ease-in-out')),
      transition('out => in', animate('300ms ease-in-out')),
    ]),
  ],
  templateUrl: './activity-viewer.component.html',
  styleUrl: './activity-viewer.component.scss',
  providers: [ActViewerService],
})
export class ActivityViewerComponent implements OnInit {
  logData$: Observable<ILogData[]> | undefined;
  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private modal: MatDialog,
    private actViewerService: ActViewerService
  ) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras?.state as { data: any };
    if (state) {
      console.log({ state });
      this.selectedUser = state;
    }
    console.log(this.selectedUser, 'selectedUser');
    this.filterForm = this.fb.group({
      textFilterValue: [''],
      activeStatus: [null],
    });
    this.filterForm.valueChanges.subscribe(() => this.applyFilter());
  }
  rowDataRef?: MatTableDataSource<any>;
  logEventTypes = Object.values(logEventEnum);
  filterForm: FormGroup;
  selectedUser?: any;
  filterVisible = false;
  toggleFilter() {
    this.filterVisible = !this.filterVisible;
    if (!this.filterVisible) {
      this.filterForm.reset();
      this.filterForm.disable();
    } else {
      this.filterForm.enable();
    }
  }
  clearFilter() {
    this.filterForm.reset();
    this.applyFilter();
  }
  setRowData(rowData: MatTableDataSource<any>) {
    this.rowDataRef = rowData;
    this.applyFilter();
  }
  applyFilter() {
    const textFilterValue = this.filterForm.get('textFilterValue')?.value
      ? this.filterForm.get('textFilterValue')?.value.trim().toLowerCase()
      : '';

    const activeStatus = this.filterForm?.get('activeStatus')?.value;
    console.log(textFilterValue, activeStatus, 'textFilterValue');

    if (this.rowDataRef) {
      this.rowDataRef.filterPredicate = (data: any, filter: string) => {
        const [text, status] = filter.split('|');
        const textMatches = JSON.stringify(data).toLowerCase().includes(text);
        const booleanMatches =
          status === 'null' || data.logEventType === status;
        const userFilter = this.selectedUser
          ? data.logUserName === this.selectedUser?.userName
          : true;
        return textMatches && booleanMatches && userFilter;
      };

      const combinedFilter = `${textFilterValue}|${activeStatus}`;
      this.rowDataRef.filter = combinedFilter;
    }
  }
  ngOnInit(): void {
    this.logData$ = this.actViewerService.getAllLogData();
    this.applyFilter();
    console.log(
      'this.logData$   ' +
        JSON.stringify(this.logData$.forEach((logdata) => console.log(logdata)))
    );
  }
  viewLogMessage = (log: any) => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'json-display-modal';
    dialogConfig.data = log;
    const modalRef = this.modal.open(jsonDisplayModalComponent, dialogConfig);
  };

  removeSelectedUser(event: MatChipEvent): void {
    console.log('REMOVAL');
    this.selectedUser = null;
    this.clearFilter();
  }
}
