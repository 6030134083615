<!-- user-management.page.html -->
<mat-toolbar color="primary">
  <mat-toolbar-row class="page-title">
    <h1>Mobile User Management</h1>
  </mat-toolbar-row>
  <mat-toolbar-row class="user-buttons">
    <div [@slideInOut]="filterVisible ? 'in' : 'out'" class="filter-panel">
      <form [formGroup]="filterForm" class="filter-form">
        <div class="filter-field">
          <mat-form-field appearance="fill">
            <mat-label>Filter</mat-label>
            <input
              matInput
              formControlName="textFilterValue"
              placeholder="Enter filter value"
            />
          </mat-form-field>
        </div>
        <div class="filter-field">
          <mat-form-field appearance="fill">
            <mat-label>Activity Status</mat-label>
            <mat-select formControlName="activeStatus">
              <mat-option [value]="true">Active</mat-option>
              <mat-option [value]="false">Inactive</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="filter-actions">
          <button mat-stroked-button type="button" (click)="clearFilter()">
            Clear
          </button>
        </div>
      </form>
    </div>
    <button
      mat-fab
      extended
      color="primary"
      id="add-user-button"
      (click)="toggleFilter()"
    >
      <strong>Filter</strong>
      <mat-icon id="add-user-icon">filter_alt</mat-icon>
    </button>
    <button
      mat-fab
      extended
      color="primary"
      id="add-user-button"
      (click)="openBulkUsersModal()"
    >
      <strong>Add Bulk Users</strong>
      <mat-icon id="add-user-icon">group_add</mat-icon>
    </button>
    <button
      mat-fab
      extended
      color="primary"
      id="add-user-button"
      (click)="openAddUserModal()"
    >
      <strong>Add User</strong>
      <mat-icon id="add-user-icon">person_add</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>
<app-grid
  (rowDataRef)="setRowData($event)"
  gridType="user"
  (editUserEvent)="openEditUserModal($event)"
  (jsonDisplayEvent)="openjsonDisplayModal($event)"
  (jsonDisplayLogsEvent)="displayUserActivity($event)"
/>
