import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { NavigationEnd, RouterModule, Router } from '@angular/router';
import { AppRoutes } from '../../../models/route-model';
import { BreadCrumbsComponent } from '../../bread-crumbs/bread-crumbs.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-default-home-page',
  standalone: true,
  imports: [MatCardModule, RouterModule, BreadCrumbsComponent, MatIconModule],
  templateUrl: './default-home-page.component.html',
  styleUrl: './default-home-page.component.scss',
})
export class DefaultHomePageComponent {
  appRoutes = AppRoutes;
  isChild = false;

  constructor(private router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/home') {
          this.isChild = false;
        }
      }
    });
  }
}
