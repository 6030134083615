export class SESSION_CONSTANTS {

    static getLoggedUserNameSession(): string {
        if (typeof window !== 'undefined' && window.sessionStorage)
            return sessionStorage.getItem('loggedUserName')?.toString() || '';
        else
            return '';
    }
    static setLoggedUserNameSession(value: string) {
        if (typeof window !== 'undefined' && window.sessionStorage)
            sessionStorage.setItem('loggedUserName', value);
    }
    static removeLoggedUserNameSession() {
        if (typeof window !== 'undefined' && window.sessionStorage)
            sessionStorage.removeItem('loggedUserToken');
    }

    static getLoggedTokenSession(): string {
        if (typeof window !== 'undefined' && window.sessionStorage)
            return sessionStorage.getItem('loggedUserToken')?.toString() || '';
        else
            return '';
    }
    static setLoggedTokenSession(value: string) {
        if (typeof window !== 'undefined' && window.sessionStorage)
            sessionStorage.setItem('loggedUserToken', value);
    }
    static removeLoggedTokenSession() {
        if (typeof window !== 'undefined' && window.sessionStorage)
            sessionStorage.removeItem('loggedUserToken');
    }

    static getLoggedSessionStatus(): boolean {
        if (typeof window !== 'undefined' && window.sessionStorage)
            return Boolean(sessionStorage.getItem('loggedStatus')) || false;
        else
            return false;
    }
    static setLoggedSessionStatus(value: boolean) {
        if (typeof window !== 'undefined' && window.sessionStorage)
            sessionStorage.setItem('loggedStatus', value.toString());
    }
    static removeLoggedSessionStatus() {
        if (typeof window !== 'undefined' && window.sessionStorage)
            sessionStorage.removeItem('loggedStatus');
    }

    static clearAppSession() {
        if (typeof window !== 'undefined' && window.sessionStorage) {
            sessionStorage.removeItem('loggedUserToken');
            sessionStorage.removeItem('loggedUserName');
            sessionStorage.removeItem('loggedStatus');
        }
    }
    static setAppSession(user_name: string, access_token: string, status: boolean) {
        SESSION_CONSTANTS.setLoggedUserNameSession(user_name);
        SESSION_CONSTANTS.setLoggedTokenSession(access_token);
        SESSION_CONSTANTS.setLoggedSessionStatus(true);
    }
}