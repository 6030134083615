<div class="grid-container">
  <div class="grid-scroll">
    <table mat-table [dataSource]="rowData" matSort class="mat-table">
      <!-- Dynamically generate columns -->
      @if(gridType === 'user'){
      <ng-container matColumnDef="userName" [sticky]="true">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ "userName" | readableDisplay }}
        </th>
        <td mat-cell *matCellDef="let element">
          <span [matTooltipShowDelay]="750" [matTooltip]="element['userName']">
            {{ element["userName"] }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="userFullName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ "userFullName" | readableDisplay }}
        </th>
        <td mat-cell *matCellDef="let element">
          <span
            [matTooltipShowDelay]="750"
            [matTooltip]="
              element['userFirstName'] +
              ' ' +
              (element['userMiddleName']
                ? element['userMiddleName'] + ' '
                : '') +
              element['userLastName']
            "
          >
            {{
              element["userFirstName"] +
                " " +
                (element["userMiddleName"]
                  ? element["userMiddleName"] + " "
                  : "") +
                element["userLastName"]
            }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="userEmailId">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ "userEmailId" | readableDisplay }}
        </th>
        <td mat-cell *matCellDef="let element">
          <span
            [matTooltipShowDelay]="750"
            [matTooltip]="element['userEmailId']"
          >
            {{ element["userEmailId"] }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="userAssignedRole">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ "userAssignedRole" | readableDisplay }}
        </th>
        <td mat-cell *matCellDef="let element">
          <span
            [matTooltipShowDelay]="750"
            [matTooltip]="element['userAssignedRole'] | json"
          >
            {{ element["userAssignedRole"] }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="userProductTypes">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ "userProductTypes" | readableDisplay }}
        </th>
        <td mat-cell *matCellDef="let element">
          <span
            [matTooltipShowDelay]="750"
            [matTooltip]="element['userProductTypes']"
          >
            {{ element["userProductTypes"] }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="userProductInjectorIDs">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ "userProductInjectorIDs" | readableDisplay }}
        </th>
        <td mat-cell *matCellDef="let element">
          <span>
            @for(injector of element['userProductInjectorIDs']; track injector){
            <div
              [matTooltip]="injector.injectorType + ': ' + injector.injectorID"
              [matTooltipShowDelay]="750"
            >
              {{ injector.injectorType }}: {{ injector.injectorID }}
            </div>
            }
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="userLicenseExpirationDateTime">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ "userLicenseExpirationDateTime" | readableDisplay }}
        </th>
        <td mat-cell *matCellDef="let element">
          <span>
            @for(injector of element['userProductInjectorIDs']; track injector){
            <div
              [matTooltip]="injector.injectorType + ': ' + injector.injectorID"
              [matTooltipShowDelay]="750"
            >
              {{ injector.injectorType }}:
              {{
                injector.userLicenseExpirationDateTime 
              }}
            </div>
            }
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="userIsActive">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ "userIsActive" | readableDisplay }}
        </th>
        <td mat-cell *matCellDef="let element">
          <span
            [matTooltipShowDelay]="750"
            [matTooltip]="element['userIsActive'] ? 'Active' : 'Inactive'"
          >
            @if(element['userIsActive']){
            <mat-icon>check</mat-icon>
            }@else {
            <mat-icon>close</mat-icon>
            }
          </span>
        </td>
      </ng-container>

      }@else if (gridType === 'log') {
      <ng-container matColumnDef="logEventDateTime">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ "logEventDateTime" | readableDisplay }}
        </th>
        <td mat-cell *matCellDef="let element">
          <span
            [matTooltipShowDelay]="750"
            [matTooltip]="element['logEventDateTime']"
          >
            {{ element["logEventDateTime"] | pipeCustomDateFormat }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="logEventType">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ "logEventType" | readableDisplay }}
        </th>
        <td mat-cell *matCellDef="let element">
          <span
            [matTooltipShowDelay]="750"
            [matTooltip]="element['logEventType']"
          >
            {{ element["logEventType"] }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="logSrcSystem">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ "logSrcSystem" | readableDisplay }}
        </th>
        <td mat-cell *matCellDef="let element">
          <span
            [matTooltipShowDelay]="750"
            [matTooltip]="element['logSrcSystem']"
          >
            {{ element["logSrcSystem"] }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="logUserName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ "logUserName" | readableDisplay }}
        </th>
        <td mat-cell *matCellDef="let element">
          <span
            [matTooltipShowDelay]="750"
            [matTooltip]="element['logUserName']"
          >
            {{ element["logUserName"] }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="logMessage">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ "logMessage" | readableDisplay }}
        </th>
        <td mat-cell *matCellDef="let element">
          <span
            [matTooltipShowDelay]="750"
            [matTooltip]="element['logMessage']"
          >
            {{ element["logMessage"] }}
          </span>
        </td>
      </ng-container>
      }
      <!-- Conditional action button logic based on grid type -->
      <ng-container matColumnDef="actions" stickyEnd>
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          @if(gridType === 'user'){
          <button
            mat-icon-button
            matTooltip="Edit User"
            [matTooltipShowDelay]="750"
            color="primary"
            (click)="editUser(element)"
          >
            <mat-icon>edit</mat-icon>
          </button>
          }
          <button
            mat-icon-button
            color="primary"
            (click)="jsonDisplay(element)"
            matTooltip="View User"
            [matTooltipShowDelay]="750"
          >
            <mat-icon>visibility</mat-icon>
          </button>
          @if(gridType === 'user'){
          <button
            mat-icon-button
            color="primary"
            (click)="jsonDisplayLogs(element)"
            matTooltip="View User Logs"
            [matTooltipShowDelay]="750"
          >
            <mat-icon>list_alt</mat-icon>
          </button>
          }
        </td>
      </ng-container>
      <!-- Header row -->
      <tr
        mat-header-row
        *matHeaderRowDef="columnDefs.concat(['actions']); sticky: true"
      ></tr>
      <!-- Data rows -->
      <tr
        mat-row
        *matRowDef="let row; columns: columnDefs.concat(['actions'])"
      ></tr>
    </table>
  </div>
  <mat-paginator
    [pageSizeOptions]="[20, 50, 100]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
    class="paginator"
  >
  </mat-paginator>
</div>
