import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { version } from '../../../package.json';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { AppRoutes } from '../../models/route-model';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { UnsubscribeOnDestroyAdapter } from '../../subscription/unsubscribe-ondestroy-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-header-panel',
  standalone: true,
  imports: [
    MatToolbarModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
  ],
  templateUrl: './header-panel.component.html',
  styleUrl: './header-panel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AuthService],
})
export class HeaderPanelComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnDestroy
{
  private userInfoSubscription: Subscription | undefined;
  datetimeLoggedIn = Date.now();
  currentUserName: string = '';
  version = String(version);
  isLoginSessionActive: boolean = false;

  constructor(private router: Router, 
    private authService: AuthService,
    private sharedService: SharedService) {
    super();
    this.router = router;
    this.currentUserName = this.sharedService.getCurrentUserName || '';
    this.isLoginSessionActive = this.sharedService.getIsLoginSessionActive;
    // this.subs.sink = this.sharedService.getCurrentUserName$.subscribe(value => {
    //   this.currentUserName = value?.toString() || '';
    // });
  }
  onLogout() {
    this.authService.logout();
    this.router.navigate([AppRoutes.route_startpage]);
  }
  navigateToDevaccess() {
    this.router.navigate([AppRoutes.route_devaccess], {
      relativeTo: this.router.routerState.root.firstChild,
    });
  }

  override ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
