<div id="home">
  <div class="feature">
    <mat-card
      (click)="isChild = true"
      routerLink="/{{ appRoutes.route_home }}/{{ appRoutes.route_userlist }}"
      appearance="outlined"
    >
      <mat-card-header>
        <mat-card-title-group>
          <mat-card-title>User Management</mat-card-title>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content>
        <mat-icon class="themed-icon">group</mat-icon>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="feature">
    <mat-card
      (click)="isChild = true"
      routerLink="/{{ appRoutes.route_home }}/{{
        appRoutes.route_activityviewer
      }}"
      appearance="outlined"
    >
      <mat-card-header>
        <mat-card-title-group>
          <mat-card-title>Activity Viewer</mat-card-title>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content>
        <mat-icon class="themed-icon">list_alt</mat-icon>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="feature">
    <mat-card
      (click)="isChild = true"
      routerLink="/{{ appRoutes.route_home }}/{{ appRoutes.route_devaccess }}"
      appearance="outlined"
    >
      <mat-card-header>
        <mat-card-title-group>
          <mat-card-title>Dev Access</mat-card-title>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content>
        <mat-icon class="themed-icon">settings</mat-icon>
      </mat-card-content>
    </mat-card>
  </div>
</div>
