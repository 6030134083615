export class AppRoutes {
    public static route_startpage = '';
    public static route_home = 'home';
    public static route_defaulthomepage = 'default-home-page';
    public static route_userlist = 'user-list';
    public static route_useraddedit = 'user-add-edit';
    public static route_activityviewer = 'activity-viewer';
    public static route_notfound= '**';
    public static route_errorpage= 'error';
    public static route_devaccess= 'devaccess';
}