import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AppRoutes } from '../models/route-model';
import { SharedService } from '../services/shared.service';


export const AuthGuard: CanActivateFn = (route, state) => {
    const sharedService = inject(SharedService);
    const router = inject(Router);

    const loggedStatus = Boolean(sharedService.getIsLoginSessionActive);
    console.log('getCurrentUserName ' + sharedService.getCurrentUserName);
    console.log('getCurrentAccessToken ' + sharedService.getCurrentAccessToken);
    console.log('getIsLoginSessionActive ' + sharedService.getIsLoginSessionActive);
    console.log('AuthGuardAuthGuardAuthGuardAuthGuardAuthGuard: ' + JSON.stringify(loggedStatus));
    if (loggedStatus === true) {
        return true;
    }
    router.navigate([AppRoutes.route_startpage]);
    return false;
};
  
