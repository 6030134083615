import { Injectable } from '@angular/core';
import { ICountryList } from '../../submodules/silverlight-common/lib/ts/src/interface/country.list.interface';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { configSystemEnum } from '../../submodules/silverlight-common/lib/ts/src/common-enums/config.system.enums';

@Injectable({
  providedIn: 'root',
})
export class CountryListService {
  static URL = `${environment.api_base_url}/CountryList/${configSystemEnum.PLC_APP}`;
  constructor(public http: HttpClient) {}

  getCountryList(): Observable<any> {
    return this.http.get<ICountryList[]>(CountryListService.URL);
  }
}
