import { map } from 'rxjs/operators';
import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import * as XLSX from 'xlsx';
import { plmUserRoleEnum } from '../../../submodules/silverlight-common/lib/ts/src/common-enums/user.role.enums';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { configSystemEnum } from '../../../submodules/silverlight-common/lib/ts/src/common-enums/config.system.enums';
import { UserService } from '../../services/user.service';
import { CountryListService } from '../../services/country-list.service';
import { ICountryList } from '../../../submodules/silverlight-common/lib/ts/src/interface/country.list.interface';
import { UnsubscribeOnDestroyAdapter } from '../../subscription/unsubscribe-ondestroy-adapter';
import { AuthService } from '../../services/auth.service';
import { locationData } from '../../../submodules/silverlight-common/lib/ts/src/interface/user.profile.interface';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-add-bulk-user-modal',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatSnackBarModule,
    MatIconModule,
  ],
  templateUrl: './add-bulk-user-modal.component.html',
  styleUrl: './add-bulk-user-modal.component.scss',
})
export class AddBulkUserModalComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit
{
  fileData: any; // Holds the parsed Excel data
  fileName: string = ''; // Holds the name of the uploaded file
  constructor(
    private dialogRef: MatDialogRef<AddBulkUserModalComponent>,
    private userService: UserService,
    private countryListService: CountryListService,
    private sharedService: SharedService,
    private _snackBar: MatSnackBar
  ) {
    super();
    // this.subs.sink = this.authService
    //   .getCurrentLoggedUserValueAsObservable()
    //   .subscribe((value) => {
    //     this.currentUserName = value?.toString() || '';
    //   });
  }
  ngOnInit(): void {
    this.subs.sink = this.countryListService
      .getCountryList()
      .subscribe((data) => {
        console.log(data);
        this.countryRegionApiData = data?.responseData;
      });
  }
  countryRegionApiData!: ICountryList[];
  currentUserName: string = '';
  listOfBulkUsers: any = [];

  get countryRegionMap() {

    
    if (!this.countryRegionApiData || this.countryRegionApiData.length === 0) {
      return {}; // Return an empty object if countryRegionApiData is not set
    }
    return this.countryRegionApiData.reduce((acc: any, current: any) => {
      const { country, region_HQ } = current;

      if (!acc[region_HQ]) {
        acc[region_HQ] = [];
      }

      acc[region_HQ].push(country);
      return acc;
    }, {});
  }
  downloadTemplate(): void {
    const link = document.createElement('a');
    link.href = '/assets/bulk-user-template.xlsx';
    link.download = 'BulkUserTemplate.xlsx';
    link.click();
  }
  parseExcelDate(excelSerialDate: number): string {
    const excelEpoch = new Date(Date.UTC(1900, 0, 1));
    const date = new Date(
      excelEpoch.getTime() + (excelSerialDate - 2) * 24 * 60 * 60 * 1000
    );
    // Format the date to YYYY-MM-DD
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  onFileChange(event: any): void {
    //.replace(/\s+/g, "") Removes all white spaces
    const target: DataTransfer = <DataTransfer>event.target;
    const file = target.files[0];
    console.log(file); // Log the file object
    this.fileName = file.name;
    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      const arrayBuffer = e.target.result;
      const binaryData = new Uint8Array(arrayBuffer);
      const workbook: XLSX.WorkBook = XLSX.read(binaryData, { type: 'array' });

      console.log(workbook.SheetNames); // Log the workbook object
      const bulkUserList = [];
      workbook.SheetNames.forEach((sheetName) => {
        if (sheetName === 'EXAMPLE') return;
        const sheet: XLSX.WorkSheet = workbook.Sheets[sheetName];
        console.log('Sheet Name: ', sheetName);
        this.fileData = XLSX.utils.sheet_to_json(sheet, { header: 0 });
        this.fileData.forEach((userFromExcel: any) => {
          for (let key in userFromExcel) {
            userFromExcel[key] = userFromExcel[key]
              .toString()
              .replace(/\s+/g, '');
          }
          const currentUserExcelRow: any = {
            userName: userFromExcel['User Name'],
            userFirstName: userFromExcel['First Name'],
            userMiddleName: userFromExcel['Middle Name'],
            userLastName: userFromExcel['Last Name'],
            userEmailId: userFromExcel['Email'],
            userOrganizationName: userFromExcel['Organization Name'],
            userAssignedRole: '', // This will be set based on the sheet name
            userAssignedSystem: configSystemEnum.PLM,
            userLocationinfo: [],
            userProductTypes: userFromExcel['Authorized Products'].split(','),
            userIsActive: userFromExcel['Is Active'].toLowerCase() === 'true',
            userUpdatedBy: '',
            userCreatedBy: this.sharedService.getCurrentUserName,
            userCreatedBySystemName: configSystemEnum.PLC_APP,
            userIsDevToolsAccess: false,
            // userTokenExpiryTime: '18h',
          };

          switch (sheetName) {
            case plmUserRoleEnum.FSE: {
              console.log(this.fileData); // Log the parsed data
              currentUserExcelRow.userAssignedRole = plmUserRoleEnum.FSE;
              currentUserExcelRow.userLocationinfo = this.getLocationInfo(
                userFromExcel['Authorized Countries'].split(','),
                userFromExcel['Authorized Regions'].split(','),
                plmUserRoleEnum.FSE
              );
              break;
            }
            case plmUserRoleEnum.BIOMED: {
              console.log(this.fileData); // Log the parsed data
              currentUserExcelRow.userAssignedRole = plmUserRoleEnum.BIOMED;
              currentUserExcelRow.userProductInjectorIDs =
                currentUserExcelRow.userProductTypes
                  .map((productType: string) => {
                    const productInfoArray = userFromExcel[
                      'Authorized Product Info'
                    ]
                      .split(';')
                      .map((productInfo: string) => productInfo.split('|'));
                    console.log('HIHIHI', productInfoArray);
                    for (const productInfo of productInfoArray) {
                      let [
                        injectorType,
                        injectorID,
                        userLicenseExpirationDateTime,
                      ] = productInfo;
                      injectorID = injectorID.replace(/\s+/g, '').split(',');
                      if (injectorType === productType) {
                        return {
                          injectorType,
                          injectorID,
                          userLicenseExpirationDateTime,
                        };
                      }
                    }
                    return null;
                  })
                  .filter((item: any) => item !== null);
              break;
            }
            case plmUserRoleEnum.DEALER: {
              console.log(this.fileData); // Log the parsed data
              currentUserExcelRow.userAssignedRole = plmUserRoleEnum.DEALER;
              currentUserExcelRow.userLocationinfo = [
                {
                  region: 'ALL',
                  countries: userFromExcel['Authorized Countries'].split(','),
                },
              ];
              break;
            }
            default: {
              // ERROR MESSAGE
              //material snackbar: "Invalid sheet name: ${sheetName}"
              //////
              break;
            }
          }
          this.listOfBulkUsers.push(currentUserExcelRow);
        });
      });
    };

    // Use readAsArrayBuffer instead of readAsBinaryString
    reader.readAsArrayBuffer(file);
  }
  getLocationInfo(
    selectedCountries: string[],
    selectedRegions: string[],
    userType: string
  ): locationData[] {
    if (userType === plmUserRoleEnum.FSE) {
      const result = selectedRegions.map((region) => {
        if (selectedCountries[0] === 'ALL') {
          return {
            region,
            countries: ['ALL'],
          };
        }
        const filteredCountries = this.countryRegionMap[region].filter(
          (country: string) => selectedCountries.includes(country)
        );

        return {
          region,
          countries: filteredCountries.length > 0 ? filteredCountries : [],
        };
      });

      return result.filter((item) => item.countries.length > 0);
    } else if (userType === plmUserRoleEnum.DEALER) {
      if (selectedCountries[0] === 'ALL') {
        return [
          {
            region: 'ALL',
            countries: ['ALL'],
          },
        ];
      }
      return [{ region: 'ALL', countries: selectedCountries }];
    } else {
      console.log('Invalid user type', userType);
      return [];
    }
  }
  // Method to handle form submission
  submitData(): void {
    console.log(this.listOfBulkUsers); // Log the parsed data
    if (this.listOfBulkUsers.length === 0) {
      this._snackBar.open('No users to submit', 'Close', {
        duration: 5000,
      });
      return;
    }
    this.subs.sink = this.userService
      .createBulkUserProfiles(this.listOfBulkUsers)
      .subscribe({
        next: (data) => {
          console.log(data);
          this.subs.sink = this.userService.getPLMUserProfiles().subscribe();
          this.dialogRef.close();
        },
        error: (errorResponse) => {
          console.error(errorResponse.status);
          if (errorResponse.status === 0) {
            this._snackBar.open('Server is not reachable', 'Close', {
              duration: 5000,
            });
            return;
          }
          this._snackBar.open(errorResponse.error.error, 'Close', {
            duration: 5000,
          });
        },
      });
  }
  override ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
