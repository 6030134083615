<header>
  <mat-toolbar>
    <mat-toolbar-row>
      <img
        src="../assets/BayerPasslinkCloudLogoDark.svg"
        srcset="../assets/BayerPasslinkCloudLogoLight.svg"
        height="100"
        width="300"
        alt="Bayer Logo"
        class="logo"
      />
      <span class="version">{{ version }}</span>
      <span class="spacer"></span>
      <div *ngIf="isLoginSessionActive" class="header-info-content">
        <p>{{ currentUserName }}</p>
        <div>
          <button mat-raised-button appearance="outlined" (click)="onLogout()">
            Logout
          </button>
        </div>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</header>
<hr />
