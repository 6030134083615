import { ReadableDisplayEnum } from '../../../../submodules/silverlight-common/lib/ts/src/common-enums/readable.display.enums';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ReadableDisplayPipe } from '../../../pipes/readable-display.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-json-display-modal',
  standalone: true,
  imports: [
    ReadableDisplayPipe,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: './json-display-modal.component.html',
  styleUrl: './json-display-modal.component.scss',
})
export class jsonDisplayModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    console.log('data', data);
  }
  columns = Object.keys(this.data);
  readableDisplayEnum = ReadableDisplayEnum;
  shouldRowBeDisplayed(key: string): boolean {
    return key in this.readableDisplayEnum;
  }
}
