import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { ILogData } from '../../submodules/silverlight-common/lib/ts/src/interface/log.data.interface';
import { environment } from '../environments/environment';
import { configSystemEnum } from '../../submodules/silverlight-common/lib/ts/src/common-enums/config.system.enums';
import { CreateLogDataDto } from '../../submodules/silverlight-common/lib/ts/src/dtos/create-log.data.dto';

@Injectable({
  providedIn: 'root'
})
export class ActViewerService {
  static URL = `${environment.api_base_url}/logdata/${configSystemEnum.PLC_APP}`;
  constructor(public http: HttpClient) { }
  private logDataListSubject = new BehaviorSubject<ILogData[]>([]);

  logDataList$ = this.logDataListSubject.asObservable();
  getAllLogData(): Observable<ILogData[]> {
    return this.http.get<ILogData[]>(ActViewerService.URL).pipe(
      tap((logs) => this.logDataListSubject.next(logs))
    );
  }
  createLogData(createLogDataDto: CreateLogDataDto): Observable<ILogData> {
    return this.http.post<ILogData>(ActViewerService.URL, createLogDataDto);
  }
  deleteLogData(days: number): Observable<any> {
    return this.http.delete<any>(`${environment.api_base_url}/purgelogdata/${configSystemEnum.PLC_APP}/${days}`);
  }
}
