<mat-toolbar color="primary">
  <mat-toolbar-row class="page-title">
    <h1>Activity Viewer</h1>
  </mat-toolbar-row>
  <mat-toolbar-row class="log-buttons">
    @if(selectedUser?.userName){
    <mat-chip-row (removed)="removeSelectedUser($event)">
      {{ selectedUser.userName }}
      <button matChipRemove aria-label="'remove reactive form'">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    }
    <div [@slideInOut]="filterVisible ? 'in' : 'out'" class="filter-panel">
      <form [formGroup]="filterForm" class="filter-form">
        <div class="filter-field">
          <mat-form-field appearance="fill">
            <mat-label>Filter</mat-label>
            <input
              matInput
              formControlName="textFilterValue"
              placeholder="Enter filter value"
            />
          </mat-form-field>
        </div>
        <div class="filter-field">
          <mat-form-field appearance="fill">
            <mat-label>Log Event Type</mat-label>
            <mat-select formControlName="activeStatus">
              @for( logEventType of logEventTypes; track logEventType){
                <mat-option [value]="logEventType">{{logEventType}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="filter-actions">
          <button mat-stroked-button type="button" (click)="clearFilter()">
            Clear
          </button>
        </div>
      </form>
    </div>
    <button
      mat-fab
      extended
      color="primary"
      id="add-user-button"
      (click)="toggleFilter()"
    >
      <strong>Filter</strong>
      <mat-icon id="add-user-icon">filter_alt</mat-icon>
    </button>
    <!-- <button
      mat-fab
      extended
      color="primary"
      id="add-user-button"
      (click)="toggleFilter()"
    >
      <strong>Filter</strong>
      <mat-icon id="add-user-icon">filter_alt</mat-icon>
    </button> -->
  </mat-toolbar-row>
</mat-toolbar>
<app-grid
  gridType="log"
  (jsonDisplayEvent)="viewLogMessage($event)"
  (rowDataRef)="setRowData($event)"
/>
