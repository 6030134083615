import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SESSION_CONSTANTS } from '../helpers/session.constants';

@Injectable({
  providedIn: 'root' // This makes the service available throughout the app
})
export class SharedService {

  // currentAccessToken
  private accessTokenSubject = new BehaviorSubject<string>('');
  get getCurrentAccessToken$(): Observable<string> {
    return this.accessTokenSubject.asObservable();
  }
  setAccessToken(newValue: string): void {
    this.accessTokenSubject.next(newValue);
  }
  get getCurrentAccessToken(): string {
    return SESSION_CONSTANTS.getLoggedTokenSession();
  }

  // currentUserName
  private currentUserNameSubject = new BehaviorSubject<string>('');
  get getCurrentUserName$(): Observable<string> {
    return this.currentUserNameSubject.asObservable();
  }
  setCurrentUserName(newValue: string): void {
    this.currentUserNameSubject.next(newValue);
  }
  get getCurrentUserName(): string {
    return SESSION_CONSTANTS.getLoggedUserNameSession();
  }

  // isLoginSessionActive
  private isLoginSessionActiveSubject = new BehaviorSubject<boolean>(false);
  get getIsLoginSessionActive$(): Observable<boolean> {
    return this.isLoginSessionActiveSubject.asObservable();
  }
  setIsLoginSessionActive(newValue: boolean): void {
    this.isLoginSessionActiveSubject.next(newValue);
  }
  get getIsLoginSessionActive(): boolean {
    return SESSION_CONSTANTS.getLoggedSessionStatus();
  }

  setAppLoginState(user_name: string, access_token: string, status: boolean) {
    this.setCurrentUserName(user_name);
    this.setAccessToken(access_token);
    this.setIsLoginSessionActive(status);
  }

  clearAppLoginState() {
    this.setCurrentUserName('');
    this.setAccessToken('');
    this.setIsLoginSessionActive(false);
  }
}
