<span class="json-display-container">
  <h2 mat-dialog-title>{{ data.logId ? "View Log Message" : "View User" }}</h2>
  <button mat-icon-button mat-dialog-close class="close-button">
    <mat-icon>close</mat-icon>
  </button>
  <mat-dialog-content>
    @for(column of columns; track column){ @if(shouldRowBeDisplayed(column)){
      <!-- Switch used to display fields that are out of the ordinary -->
    @switch (column) { @case ("userProductInjectorIDs") {
    <div class="json-display-row">
      <h3>{{ column | readableDisplay }}:</h3>
      <span>
        @for(injector of data[column]; track injector){
        <p>{{ injector.injectorType }}: {{ injector.injectorID }}</p>
        }
      </span>
    </div>
    } @case ("userLocationinfo") {
    <div class="json-display-row">
      <h3>{{ column | readableDisplay }}:</h3>
      <span>
        @for(location of data[column]; track location){
        <p>{{ location.region }}: {{ location.countries }}</p>
        }
      </span>
    </div>
    } @default {
    <div
      [class]="column === 'logMessage' ? 'message-scroll' : 'json-display-row'"
    >
      <h3>{{ column | readableDisplay }}:</h3>
      <p>{{ data[column] }}</p>
    </div>
    } }
    }}
  </mat-dialog-content>
</span>
