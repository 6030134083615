import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { UserLoginInDto } from '../../submodules/silverlight-common/lib/ts/src/dtos/user.login.dto';
import { environment } from '../environments/environment';
import { IUserLogin } from '../../submodules/silverlight-common/lib/ts/src/interface/user.login.interface';
import { configSystemEnum } from '../../submodules/silverlight-common/lib/ts/src/common-enums/config.system.enums';
import { SharedService } from './shared.service';
import { SESSION_CONSTANTS } from '../helpers/session.constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  static URL = `${environment.api_base_url}/auth/login/${configSystemEnum.PLC_APP}`;

  constructor(private http: HttpClient, private sharedService: SharedService) {
  }

  login(userLogin: UserLoginInDto): Observable<IUserLogin> {
    return this.http.post<IUserLogin>(AuthService.URL, userLogin).pipe(
      tap((data: any) => {
        const encUserInfo: IUserLogin = data['responseData'];
        SESSION_CONSTANTS.setAppSession(encUserInfo.user_name, encUserInfo.access_token, true);
        this.sharedService.setAppLoginState(encUserInfo.user_name, encUserInfo.access_token, true);

        console.log('getCurrentUserName ' + this.sharedService.getCurrentUserName);
        console.log('getCurrentAccessToken ' + this.sharedService.getCurrentAccessToken);
        console.log('getIsLoginSessionActive ' + this.sharedService.getIsLoginSessionActive);
      })
    );
  }

  logout() {
    SESSION_CONSTANTS.clearAppSession();
    this.sharedService.clearAppLoginState();

    console.log('getCurrentUserName ' + this.sharedService.getCurrentUserName);
    console.log('getCurrentAccessToken ' + this.sharedService.getCurrentAccessToken);
    console.log('getIsLoginSessionActive ' + this.sharedService.getIsLoginSessionActive);
  }
}
