import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { SharedService } from "../services/shared.service";

export const AuthInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next:
    HttpHandlerFn) => {
        const sharedService = inject(SharedService);
        const userTokenValue =  sharedService.getCurrentAccessToken;
    const modifiedReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${userTokenValue}`),
    });
    return next(modifiedReq);
}; 
