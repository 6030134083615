import { ConfigService } from './../../services/config.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  signal,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { UnsubscribeOnDestroyAdapter } from '../../subscription/unsubscribe-ondestroy-adapter';
import { IConfigData } from '../../../submodules/silverlight-common/lib/ts/src/interface/config.data.interface';
import { plcUserRoleEnum } from '../../../submodules/silverlight-common/lib/ts/src/common-enums/user.role.enums';
import { TrimDirective } from '../trim.directive';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActViewerService } from '../../services/act-viewer.service';
import { UserService } from '../../services/user.service';
import { configSystemEnum } from '../../../submodules/silverlight-common/lib/ts/src/common-enums/config.system.enums';
import { AuthService } from '../../services/auth.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-dev-access',
  templateUrl: './dev-access.component.html',
  styleUrl: './dev-access.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatChipsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    FormsModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatToolbarModule,
    TrimDirective,
  ],
})
export class DevAccessComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit
{
  constructor(
    private fb: FormBuilder,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    private actViewerService: ActViewerService,
    private userService: UserService,
    private sharedService: SharedService
  ) {
    super();
    this.userForm = this.fb.group(
      {
        username: [
          '',
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(6),
          ],
        ],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]], // This is a dummy validator to test the passwordMatchValidator
        role: ['', Validators.required],
      },
      { validator: this.passwordMatchValidator }
    );
    // this.subs.sink = this.authService
    //   .getCurrentLoggedUserValueAsObservable()
    //   .subscribe((value) => {
    //     this.currentUserName = value?.toString() || '';
    //   });
    this.currentUserName = sharedService.getCurrentUserName?.toString() || '';
  }
  currentUserName: string = '';
  hide = signal(true);
  hideConfirm = signal(true);
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  passwordMatchValidator(form: FormGroup) {
    console.log(
      {
        first: form.get('password')?.value,
        second: form.get('confirmPassword')?.value,
      },
      form.get('password')?.value === form.get('confirmPassword')?.value
    );
    return form.get('password')?.value === form.get('confirmPassword')?.value
      ? null
      : { mismatch: true };
  }
  daysControl = new FormControl(0);
  productTypes: string[] = [];
  PLCRoles: plcUserRoleEnum[] = Object.keys(
    plcUserRoleEnum
  ) as plcUserRoleEnum[];
  plcUserRoleEnum = plcUserRoleEnum;
  ngOnInit(): void {
    this.initializeProductTypes();
  }
  initializeProductTypes() {
    this.subs.sink = this.configService.getConfigData().subscribe((data) => {
      console.log('Config data:', data);
      this.productTypes = data?.responseData
        ?.find((config: IConfigData) => {
          return config?.keyName === 'InjectorTypes';
        })
        ?.keyValue.replace(/\s+/g, '')
        .split(',');
      this.cdr.detectChanges();
    });
  }
  saveProductTypes() {
    this.subs.sink = this.configService
      .updateProductTypes(this.productTypes)
      .subscribe({
        next: (data) => {
          console.log('Product types updated:', data);
          this._snackBar.open('Product types updated', 'Close', {
            duration: 2000,
          });
          this.initializeProductTypes();
        },
        error: (err) => {
          console.error('Error updating product types:', err);
          this._snackBar.open('Error updating product types', 'Close', {
            duration: 2000,
          });
        },
      });
  }
  userForm: FormGroup;

  addProductType(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = event.value.toUpperCase();

    if ((value || '').trim()) {
      this.productTypes.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
  }

  removeProductType(item: string): void {
    const index = this.productTypes.indexOf(item);
    if (index >= 0) {
      this.productTypes.splice(index, 1);
    }
  }

  deleteLogs(): void {
    console.log('Delete debug logs clicked', this.daysControl.value);
    if (this.daysControl.value) {
      this.subs.sink = this.actViewerService
        .deleteLogData(this.daysControl.value)
        .subscribe({
          next: (data) => {
            console.log('Logs deleted:', data);
            this._snackBar.open('Logs deleted', 'Close', {
              duration: 2000,
            });
          },
          error: (err) => {
            console.error('Error deleting logs:', err);
            this._snackBar.open('Error deleting logs: '+ err?.message, 'Close', {
              duration: 2000,
            });
          },
        });
    }
  }

  addUser(): void {
    if (this.userForm.valid) {
      const userFormValue = this.userForm.value;
      const userToBesaved: any = {
        userName: userFormValue.username,
        userFirstName: 'N/A',
        userMiddleName: 'N/A',
        userLastName: 'N/A',
        userPassword: userFormValue.password,
        userEmailId: userFormValue.email,
        userOrganizationName: 'N/A',
        userAssignedRole: userFormValue.role,
        userAssignedSystem: configSystemEnum.PLC_APP,
        userLocationinfo: { region: 'ALL', country: 'ALL' },
        userProductTypes: 'ALL',
        userIsActive: true,
        userCreatedBy: this.currentUserName,
        userCreatedBySystemName: configSystemEnum.PLC_APP,
        userIsDevToolsAccess: true,
      };
      console.log('User added:', userToBesaved);
      this.subs.sink = this.userService
        .createUserProfile(userToBesaved)
        .subscribe({
          next: (data) => {
            console.log('User added:', data);
            this._snackBar.open('User added', 'Close', {
              duration: 2000,
            });
            this.resetForm(this.userForm);
          },
          error: (err) => {
            console.error('Error adding user:', err);
            this._snackBar.open('Error adding user', 'Close', {
              duration: 2000,
            });
          },
        });
    }
  }
  toggleViewPassword = (confirm?: string) => {
    if (confirm === 'confirm') {
      this.hideConfirm.set(!this.hideConfirm());
      return;
    }
    this.hide.set(!this.hide());
  };

  incrementDays() {
    let currentDays = this.daysControl.value || 0;
    this.daysControl.setValue(currentDays + 1);
  }

  decrementDays() {
    let currentDays = this.daysControl.value || 0;
    if (currentDays > 0) {
      this.daysControl.setValue(currentDays - 1);
    }
  }

  onInput(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value;
    this.daysControl.setValue(Number(inputValue));
  }
  resetForm(form: FormGroup) {
    form.reset({ emitEvent: false }); // Reset the form values
    form.markAsPristine({ emitEvent: false }); // Mark the form as pristine (untouched)
    form.markAsUntouched({ emitEvent: false }); // Mark the form as untouched
    form.updateValueAndValidity({ emitEvent: false }); // Update validity without emitting events
  }
}
