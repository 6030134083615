import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  signal,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AppRoutes } from '../../models/route-model';
import { MatIconModule } from '@angular/material/icon';
import { UserLoginInDto } from '../../../submodules/silverlight-common/lib/ts/src/dtos/user.login.dto';
import { AuthService } from '../../services/auth.service';
import { catchError, of } from 'rxjs';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { HeaderPanelComponent } from '../header-panel/header-panel.component';
import { LogHandler } from '../../helpers/log.handler';
import { logEventEnum } from '../../../submodules/silverlight-common/lib/ts/src/common-enums/log.event.enums';
import { ActViewerService } from '../../services/act-viewer.service';
import { SharedService } from '../../services/shared.service';

/**
 * Component for displaying the login page.
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    HeaderPanelComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AuthService],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup = new FormGroup({});

  constructor(
    private router: Router,
    private authService: AuthService,
    private actViewerService: ActViewerService,
    private sharedService: SharedService,
    private cdr: ChangeDetectorRef,

  ) {
    this.router = router;

    if (this.sharedService.getIsLoginSessionActive) {
      console.log(
        'User already logged info' +
        JSON.stringify(this.sharedService.getCurrentAccessToken)
      );
      this.router.navigate([AppRoutes.route_home]);
    }
  }
  hide = signal(true);
  errorMessage = '';
  toggleViewPassword = () => {
    this.hide.set(!this.hide());
  };

  ngOnInit() {
    this.loginForm = new FormGroup({
      ctrlUsernameOremail: new FormControl('', [Validators.required]),
      ctrlPass: new FormControl('', Validators.required),
    });
  }

  onSubmit(): void {
    const loginData: UserLoginInDto = {
      usernameOremail: this.loginForm.get('ctrlUsernameOremail')?.value,
      pass: this.loginForm.get('ctrlPass')?.value,
    };
    this.authService.login(loginData).subscribe({
      next: (data) => {
        console.log('Login successful', data);
        this.router.navigate([AppRoutes.route_home]);
        new LogHandler(this.sharedService, this.actViewerService).formatLog('user logged in PLC APP', logEventEnum.INFO);
      },
      error: (error) => {
        console.error('Login failed', error);
        // Handle login error (e.g., show error message)
        this.loginForm.setErrors({ loginFailed: true });
        return of('');
      },
    });
  }
}
