<button mat-icon-button mat-dialog-close class="close-button">
  <mat-icon>close</mat-icon>
</button>
<mat-dialog-content id="bulk-user-container">
  <h2 mat-dialog-title>Add Bulk Users</h2>
  <!-- Button to download the Excel template -->
  <div class="bulk-user-flex-item">
    <label for="fileUpload" class="file-upload-label"
      >Download Bulk User Template</label
    >
    <button mat-raised-button color="primary" (click)="downloadTemplate()">
      <mat-icon>download</mat-icon> Template
    </button>
  </div>
  <div class="bulk-user-flex-item">
    <label for="fileUpload" class="file-upload-label"
      >Upload Excel Template</label
    >
    <button mat-raised-button color="primary" (click)="fileInput.click()">
      <mat-icon>attach_file</mat-icon> Upload File
    </button>
    <input
      #fileInput
      type="file"
      (change)="onFileChange($event)"
      accept=".xls, .xlsx"
      style="display: none"
    />
    <p>{{ fileName || "No File Selected"}}</p>
  </div>
  <!-- Submit button -->
  <mat-dialog-actions>
    <button mat-raised-button color="accent"  (click)="submitData()">
      Submit
    </button>
  </mat-dialog-actions>
</mat-dialog-content>
