<!-- user-modal.component.html -->
<h2 mat-dialog-title>
  @switch (this.data.formType) { @case ('add') { Add User } @case ('edit') {
  Edit User } @default { View User } }
  <button mat-icon-button mat-dialog-close class="close-button">
    <mat-icon>close</mat-icon>
  </button>
</h2>
<!-- <pre>Form values: {{ userProductInfoFormGroup.value | json }}</pre> -->
<mat-dialog-content class="add-edit-content">
  <mat-stepper #stepper>
    <mat-step
      [stepControl]="userInfoFormGroup"
      errorMessage="User Info Form Incomplete."
    >
      <form [formGroup]="userInfoFormGroup">
        <ng-template matStepLabel>User Info</ng-template>
        <mat-form-field>
          <mat-label>{{ ReadableDisplay.userAssignedRole }}</mat-label>
          <mat-select
            formControlName="userAssignedRole"
          >
            @for(userAssignedRole of userAssignedRoles; track $index){
            <mat-option [value]="userAssignedRole">{{
              userRoleEnumForTemplate[userAssignedRole]
            }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ ReadableDisplay.userName }}</mat-label>
          <input

            matInput
            Trim
            formControlName="userName"
            placeholder="{{ ReadableDisplay.userName }}"
            maxlength="6"
          />
          @if (userInfoFormGroup.get('userName')?.hasError('required') ){
          <mat-error> {{ ReadableDisplay.userName }} is required </mat-error>
          }
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>{{ ReadableDisplay.userFirstName }}</mat-label>
          <input
            matInput
            Trim
            formControlName="userFirstName"
            placeholder="{{ ReadableDisplay.userFirstName }}"
          />
          @if (userInfoFormGroup.get('userFirstName')?.hasError('required')) {
          <mat-error>
            {{ ReadableDisplay.userFirstName }} is required
          </mat-error>
          }
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>{{ ReadableDisplay.userMiddleName }}</mat-label>
          <input
            matInput
            Trim
            formControlName="userMiddleName"
            placeholder="{{ ReadableDisplay.userMiddleName }}"
          />
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>{{ ReadableDisplay.userLastName }}</mat-label>
          <input
            matInput
            Trim
            formControlName="userLastName"
            placeholder="{{ ReadableDisplay.userLastName }}"
          />
          @if (userInfoFormGroup.get('userLastName')?.hasError('required')) {
          <mat-error>
            {{ ReadableDisplay.userLastName }} is required
          </mat-error>
          }
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>{{ ReadableDisplay.userEmailId }}</mat-label>
          <input matInput
          Trim formControlName="userEmailId" placeholder="Email" type="email" />
          @if (userInfoFormGroup.get('userEmailId')?.hasError('required')) {
          <mat-error> {{ ReadableDisplay.userEmailId }} is required </mat-error>
          } @if (userInfoFormGroup.get('userEmailId')?.hasError('email')) {
          <mat-error>
            {{ ReadableDisplay.userEmailId }} format is required
          </mat-error>
          }
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>{{ ReadableDisplay.userOrganizationName }}</mat-label>
          <input
            matInput
            Trim
            formControlName="userOrganizationName"
            placeholder="{{ ReadableDisplay.userOrganizationName }}"
          />
          @if
          (userInfoFormGroup.get('userOrganizationName')?.hasError('required'))
          {
          <mat-error>
            {{ ReadableDisplay.userOrganizationName }} is required
          </mat-error>
          }
        </mat-form-field>
        @if(userInfoFormGroup.get('userAssignedRole')?.value ==="FSE")
        {<mat-form-field appearance="fill">
          <mat-label>{{ ReadableDisplay.userRegion }}</mat-label>
          <mat-select
            placeholder="{{ ReadableDisplay.userRegion }}"
            formControlName="userRegion"
            multiple
          >
            @for(region of regionsArray; track $index){
            <mat-option [value]="region">{{ region }}</mat-option>
            }
          </mat-select>
          @if (userInfoFormGroup.get('userRegion')?.hasError('required'))
          {
          <mat-error> {{ ReadableDisplay.userRegion }} is required </mat-error>
          } </mat-form-field
        >} @if(userInfoFormGroup.get('userAssignedRole')?.value === "FSE" ||
        userInfoFormGroup.get('userAssignedRole')?.value === "DEALER"){
        <mat-form-field appearance="fill">
          <mat-label> {{ ReadableDisplay.userCountry }} </mat-label>
          <mat-select
            placeholder="{{ ReadableDisplay.userCountry }}"
            formControlName="userCountry"
            multiple
          >
          <mat-option value="ALL">ALL</mat-option>

            @for (country of countriesArray; track $index) {
            <mat-option [value]="country" [disabled]="isAllCountriesSelected">{{ country }}</mat-option>
            }
          </mat-select>
          @if
          (userInfoFormGroup.get('userCountry')?.hasError('required')) {
          <mat-error> {{ ReadableDisplay.userCountry }} is required </mat-error>
          }
        </mat-form-field>
        }
        <div id="active-toggle">
          <label>{{ ReadableDisplay.userIsActive }}:</label>
          <mat-slide-toggle formControlName="userIsActive"></mat-slide-toggle>
          @if (userInfoFormGroup.get('userIsActive')?.hasError('required')) {
          <mat-error>
            {{ ReadableDisplay.userIsActive }} is required
          </mat-error>
          }
        </div>
      </form>
      <mat-dialog-actions>
        <button mat-button matStepperNext  [disabled]="userInfoFormGroup.invalid">Next</button>
      </mat-dialog-actions>
    </mat-step>
    <mat-step
      [stepControl]="userProductInfoFormGroup"
      errorMessage="Product Info Form Incomplete."
    >
      <form [formGroup]="userProductInfoFormGroup">
        <ng-template matStepLabel>Product Info</ng-template>
        @if(userInfoFormGroup.get('userAssignedRole')?.value ===
        userRoleEnumForTemplate.BIOMED){
        <!--Each injector has a row of values-->
        <div formArrayName="userProductInjectorIDs">
          @for(injector of injectorIDs.controls; track injector; let i =
          $index){
          <div [formGroupName]="i" class="product-row">
            <mat-form-field appearance="fill">
              <mat-label>{{ ReadableDisplay.userProductTypes }}</mat-label>
              <mat-select
                placeholder="{{ ReadableDisplay.userProductTypes }}"
                formControlName="injectorType"
              >
                @for(productType of productTypes; track $index){
                <mat-option
                  [disabled]="!availableProductTypes.includes(productType)"
                  [value]="productType"
                  >{{ productType }}</mat-option
                >
                }
              </mat-select>
              @if (injector.get('injectorType')?.hasError('required')) {
              <mat-error>
                {{ ReadableDisplay.userProductTypes }} is required
              </mat-error>
              }
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label
                >{{ ReadableDisplay.userProductInjectorIDs }} :
                {{ injector.get("injectorType")?.value }}</mat-label
              >
              <mat-chip-grid
                #chipGrid
                aria-label="Enter injectorIDs"
                formControlName="injectorID"
              >
                @for (injectorID of injector.get('injectorID')?.value; track
                injectorID; let j = $index) {
                <mat-chip-row
                  (removed)="removeId(i, j)"
                  [editable]="true"
                  [aria-description]="'press enter to edit ' + injectorID"
                >
                  {{ injectorID }}
                  <button
                    matChipRemove
                    [attr.aria-label]="'remove ' + injectorID"
                  >
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
                }

                <input
                  placeholder="Ex:017829"
                  [matChipInputFor]="chipGrid"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="addId(i, $event)"
                >
                <mat-icon  matSuffix>add</mat-icon>
              </mat-chip-grid>
              @if (injector.get('injectorID')?.hasError('required')) {
              <mat-error>
                {{ ReadableDisplay.userProductInjectorIDs }} are required
              </mat-error>
              }
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label
                >{{ injector.get("injectorType")?.value }} license expiration
                date</mat-label
              >
              <input
                matInput
                Trim
                [matDatepicker]="picker"
                formControlName="userLicenseExpirationDateTime"
              />
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            @if (injectorIDs.length > 1) {
            <button mat-button (click)="removeProduct(i)">
              Remove Product
            </button>
            }
          </div>
          }
        </div>
        <button
          mat-button
          [disabled]="
            !(availableProductTypes.length > 0) ||
            injectorIDs.value.length >= productTypes.length
          "
          (click)="addProduct(true)"
        >
          Add Injector
        </button>

        } @else {
        <mat-form-field appearance="fill">
          <mat-label>{{ ReadableDisplay.userProductTypes }}</mat-label>
          <mat-select
            placeholder="{{ ReadableDisplay.userProductTypes }}"
            formControlName="userProductTypes"
            multiple
            (selectionChange)="onProductTypeChange($event.value)"
          >
            @for(productType of productTypes; track $index){
            <mat-option [value]="productType">{{ productType }}</mat-option>
            }
          </mat-select>
          @if
          (userProductInfoFormGroup.get('userProductTypes')?.hasError('required'))
          {
          <mat-error>
            {{ ReadableDisplay.userProductTypes }} is required
          </mat-error>
          }
        </mat-form-field>
        }
      </form>
      <mat-dialog-actions>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button [disabled]="isSubmitDisabled" (click)="saveUser()">Submit</button>
        <!-- <button mat-button (click)="saveUser()">Submit</button> -->
      </mat-dialog-actions>
    </mat-step>
  </mat-stepper>
</mat-dialog-content>
