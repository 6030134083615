import { ConfigService } from './../../services/config.service';
import { plmUserRoleEnum } from './../../../submodules/silverlight-common/lib/ts/src/common-enums/user.role.enums';
import {
  IUserProfile,
  locationData,
} from './../../../submodules/silverlight-common/lib/ts/src/interface/user.profile.interface';
import { MatChipsModule } from '@angular/material/chips';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  inject,
  Inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import {
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
  FormGroup,
  FormControl,
  FormArray,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideNativeDateAdapter } from '@angular/material/core';
import { COMMA, ENTER, V } from '@angular/cdk/keycodes';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { JsonPipe, KeyValuePipe } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { UserService } from '../../services/user.service';
import { CountryListService } from '../../services/country-list.service';
import { ICountryList } from '../../../submodules/silverlight-common/lib/ts/src/interface/country.list.interface';
import { ReadableDisplayEnum } from '../../../submodules/silverlight-common/lib/ts/src/common-enums/readable.display.enums';
import { configSystemEnum } from '../../../submodules/silverlight-common/lib/ts/src/common-enums/config.system.enums';
import { UnsubscribeOnDestroyAdapter } from '../../subscription/unsubscribe-ondestroy-adapter';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IConfigData } from '../../../submodules/silverlight-common/lib/ts/src/interface/config.data.interface';
import { TrimDirective } from '../trim.directive';
import { LogHandler } from '../../helpers/log.handler';
import { logEventEnum } from '../../../submodules/silverlight-common/lib/ts/src/common-enums/log.event.enums';
import { ActViewerService } from '../../services/act-viewer.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-add-edit-user-modal',
  standalone: true,
  imports: [
    JsonPipe,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatChipsModule,
    MatIconModule,
    MatDatepickerModule,
    MatCheckboxModule,
    KeyValuePipe,
    TrimDirective,
    MatSlideToggleModule,
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
    provideNativeDateAdapter(),
  ],
  templateUrl: './user-add-edit.component.html',
  styleUrl: './user-add-edit.component.scss',
})
export class AddEditUserModalComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit
{
  private _snackBar = inject(MatSnackBar);
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  readonly announcer = inject(LiveAnnouncer);
  countryRegionApiData!: ICountryList[];

  constructor(
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    public modalRef: MatDialogRef<AddEditUserModalComponent, MatInputModule>,
    @Inject(MAT_DIALOG_DATA)
    public data: { user: IUserProfile; formType: string },
    private userService: UserService,
    private countryListService: CountryListService,
    private configService: ConfigService,
    private actViewerService: ActViewerService,
    private sharedService: SharedService
  ) {
    super();
    // this.subs.sink = this.authService
    //   .getCurrentLoggedUserValueAsObservable()
    //   .subscribe((value) => {
    //     this.currentUserName = value?.toString() || '';
    //   });
  }
  override ngOnDestroy() {
    this.subs.unsubscribe();
  }
  userRoleEnumForTemplate = plmUserRoleEnum;
  get countryRegionMap() {

    if (!this.countryRegionApiData || this.countryRegionApiData.length === 0) {
      return {}; // Return an empty object if countryRegionApiData is not set
    }
    return this.countryRegionApiData.reduce((acc: any, current: any) => {
      const { country, region_HQ } = current;

      if (!acc[region_HQ]) {
        acc[region_HQ] = [];
      }

      acc[region_HQ].push(country);
      return acc;
    }, {});
  }
  userInfoFormGroup!: FormGroup;
  userProductInfoFormGroup!: FormGroup;
  isAllCountriesSelected = false;
  ngOnInit(): void {
    this.userInfoFormGroup = this.formBuilder.group({
      userAssignedSystem: 'PLM',
      userAssignedRole: ['', Validators.required],
      userName: [
        '',
        [Validators.required, Validators.minLength(4), Validators.maxLength(6)],
      ],
      userFirstName: ['', Validators.required],
      userMiddleName: [''],
      userLastName: ['', Validators.required],
      userEmailId: ['', [Validators.required, Validators.email]],
      userCountry: [''],
      userRegion: [''],
      userOrganizationName: ['Bayer', Validators.required],
      userIsActive: [true, Validators.required],
      //The following I dont have displayed
      userCurrentlyLoggedIn: [''],
      userLastLoginTime: [''],
      userLastLoginLocation: [''],
      userCreatedBySystemName: [configSystemEnum.PLC_APP],
      //
    });
    this.userProductInfoFormGroup = this.formBuilder.group({
      userProductTypes: [[], Validators.required],
      userProductInjectorIDs: this.formBuilder.array([
        this.formBuilder.group({
          injectorType: [''],
          injectorID: [[]],
          userLicenseExpirationDateTime: [''],
        }),
      ]),
    });
    this.subs.sink = this.countryListService
      .getCountryList()
      .subscribe((data) => {
        console.log(data);
        this.countryRegionApiData = data?.responseData;
        if (this.data.formType !== 'add')
          this.populateCurrentUserCountryRegion(this.data.user);
      });
    this.subs.sink = this.configService.getConfigData().subscribe((data) => {
      this.productTypes = data?.responseData
        ?.find((config: IConfigData) => {
          return config?.keyName === 'InjectorTypes';
        })
        ?.keyValue.replace(/\s+/g, '')
        .split(',');
    });
    if (this.data.formType === 'edit') {
      this.userInfoFormGroup.patchValue(this.data.user);
      const currentUserType = this.data.user.userAssignedRole;
      if (currentUserType === plmUserRoleEnum.BIOMED) {
        this.populateProductForm(this.data.user.userProductInjectorIDs);
        this.applyConditionalInjectorIDValidators(currentUserType);
      }
      this.userProductInfoFormGroup.patchValue(this.data.user);
      this.formTypeSpecificControlDisable();
    }
    this.onFormChanges();
  }
  formTypeSpecificControlDisable() {
    if (this.data.formType === 'edit') {
      console.log('Disabling form fields');
      this.userInfoFormGroup.get('userName')?.disable();
      this.userInfoFormGroup.get('userAssignedRole')?.disable();
      this.userInfoFormGroup.get('userAssignedSystem')?.disable();
      this.userInfoFormGroup.get('userEmailId')?.disable();
    }
    if (this.data.formType === 'view') {
      Object.keys(this.userInfoFormGroup.controls).forEach((key) => {
        const control = this.userInfoFormGroup.get(key);
        control?.disable({ onlySelf: true, emitEvent: false });
      });
      Object.keys(this.userProductInfoFormGroup.controls).forEach((key) => {
        const control = this.userProductInfoFormGroup.get(key);
        control?.disable({ onlySelf: true, emitEvent: false });
      });
    }
  }

  populateCurrentUserCountryRegion(user: IUserProfile) {
    const userRegion = user.userLocationinfo
      .map((location) => location.region)
      .flat();
    const userCountry = user.userLocationinfo
      .map((location) => location.countries)
      .flat();
    console.log({ userRegion, userCountry });
    console.log(this.countryRegionApiData);
    this.userInfoFormGroup.patchValue({
      userRegion,
      userCountry,
    });
  }
  resetInjectorIDsField() {
    this.injectorIDs.clear();
    this.userProductInfoFormGroup.get('userProductTypes')?.reset();
    this.addProduct();
  }
  populateProductForm(data: any) {
    console.log('Populating product form with:', data);
    // Clear previous product details
    this.injectorIDs.clear();
    // Populate form with new data
    data.forEach((item: any) => {
      const productGroup = this.formBuilder.group({
        injectorType: [item.injectorType],
        injectorID: item.injectorID.map((id: string) => new FormControl(id)),
        userLicenseExpirationDateTime: new FormControl(
          new Date().toISOString()
        ),
      });
      this.injectorIDs.push(productGroup);
    });
  }

  //CONDITIONAL FORM CONTROL LOGIC
  private onFormChanges(): void {
    const countryControl = this.userInfoFormGroup.get('userCountry');
    const regionControl = this.userInfoFormGroup.get('userRegion');
    const productTypesControl =
      this.userProductInfoFormGroup.get('userProductTypes');
    this.userInfoFormGroup
      .get('userAssignedRole')
      ?.valueChanges.subscribe((role) => {
        this.assignedRoleSpecificDisable();
        this.resetInjectorIDsField();
        this.applyConditionalInjectorIDValidators(role);

        if (role === 'FSE') {
          countryControl?.setValidators([Validators.required]);
          regionControl?.setValidators([Validators.required]);
          productTypesControl?.setValidators([Validators.required]);
          this.injectorIDs.clearValidators();
        }
        if (role === 'DEALER') {
          countryControl?.setValidators([Validators.required]);
          regionControl?.clearValidators();
          productTypesControl?.setValidators([Validators.required]);
        }
        if (role === 'BIOMED') {
          countryControl?.clearValidators();
          regionControl?.clearValidators();
          productTypesControl?.clearValidators();
        }
        countryControl?.updateValueAndValidity();
        regionControl?.updateValueAndValidity();
        productTypesControl?.updateValueAndValidity();
        this.injectorIDs.updateValueAndValidity();
      });

    regionControl?.valueChanges.subscribe((region) => {
      this.assignedRoleSpecificDisable();
    });

    countryControl?.valueChanges.subscribe((country) => {
      if (country?.includes('ALL')) {
        this.isAllCountriesSelected = true;
        this.userInfoFormGroup
          .get('userCountry')
          ?.setValue(['ALL'], { emitEvent: false });
      } else {
        this.isAllCountriesSelected = false;
      }
    });
  }
  logInvalidControls(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);
      if (control && control.invalid) {
        console.log(`Control '${key}' is invalid. Errors:`, control.errors);
      }
    });
  }

  applyConditionalInjectorIDValidators(userType: string) {
    this.injectorIDs.controls.forEach((group) => {
      const injectorIDControl = group.get('injectorID');
      const injectorTypeControl = group.get('injectorType');
      const userLicenseExpirationDateTimeControl = group.get(
        'userLicenseExpirationDateTime'
      );
      if (userType === 'BIOMED') {
        console.log('✅✅✅✅✅✅');
        injectorIDControl?.setValidators([Validators.required]);
        userLicenseExpirationDateTimeControl?.setValidators([
          Validators.required,
        ]);
        injectorTypeControl?.setValidators([Validators.required]);
      } else {
        console.log('🛑🛑🛑🛑🛑');
        console.log('Clearing validators for injectorIDControl');
        injectorIDControl?.clearValidators();
        userLicenseExpirationDateTimeControl?.clearValidators();
        injectorTypeControl?.clearValidators();
      }
      injectorIDControl?.updateValueAndValidity();
    });
  }
  // {"label":"Home","url":"/home"}]
  //{"label":"Home","url":"/home"}]

  //////////////////////////
  assignedRoleSpecificDisable(): void {
    const role = this.userInfoFormGroup.get('userAssignedRole')?.value;
    const region = this.userInfoFormGroup.get('userRegion')?.value;
    this.userInfoFormGroup?.get('userCountry')?.reset();
    if (role === 'FSE' && !region?.length) {
      this.userInfoFormGroup?.get('userCountry')?.disable();
    } else {
      this.userInfoFormGroup?.get('userCountry')?.enable();
    }
  }
  get isSubmitDisabled(): boolean {
    return (
      this.userInfoFormGroup.invalid || this.userProductInfoFormGroup.invalid
    );
  }

  get regionsArray() {
    return Object.keys(this.countryRegionMap);
  }

  get countriesArray() {
    if (
      this.data.formType === 'edit' &&
      this.data.user.userAssignedRole === plmUserRoleEnum.DEALER
    ) {
      console.log('DEALER');
      return this.regionsArray
        .map((region) => this.countryRegionMap[region])
        .flat();
    }
    if (this.userInfoFormGroup.value?.userRegion) {
      return this.userInfoFormGroup.value?.userRegion
        .map((region: string) => this.countryRegionMap[region])
        .flat();
    }
    return Array.from(new Set(Object.values(this.countryRegionMap).flat()));
  }
  ReadableDisplay = ReadableDisplayEnum; // This should be data to display the nice user friendly user data fields
  userAssignedRoles: plmUserRoleEnum[] = Object.keys(
    plmUserRoleEnum
  ) as plmUserRoleEnum[];
  productTypes!: string[];
  sourceSystem: configSystemEnum[] = Object.keys(
    configSystemEnum
  ) as configSystemEnum[];
  currentUserName: string = '';

  // //CHIPS LOGIC
  get injectorIDs(): FormArray {
    return this.userProductInfoFormGroup.get(
      'userProductInjectorIDs'
    ) as FormArray;
  }
  onProductTypeChange(selectedProductTypes: string[]) {
    selectedProductTypes.forEach((productType) => {
      const productGroup = this.formBuilder.group({
        injectorType: [productType],
        injectorID: this.formBuilder.control([]),
        userLicenseExpirationDateTime: this.formBuilder.control(''),
      });
      console.log('Adding product group:', productGroup);
      this.injectorIDs.push(productGroup);
    });
  }
  get availableProductTypes(): string[] {
    const selectedTypes = this.injectorIDs.value.map(
      (product: any) => product.injectorType
    );
    if (this.productTypes)
      return this.productTypes.filter((type) => !selectedTypes.includes(type));
    return [];
  }

  createProductFormGroup(): FormGroup {
    return this.formBuilder.group({
      injectorType: [''],
      injectorID: [[]],
      userLicenseExpirationDateTime: [''],
    });
  }
  addProduct(detectChange: boolean = false) {
    if (this.availableProductTypes.length > 0) {
      const newProductGroup = this.createProductFormGroup();
      Object.keys(newProductGroup.controls).forEach((key) => {
        newProductGroup.get(key)?.setValidators([Validators.required]);
      });
      this.injectorIDs.push(newProductGroup);
      if (detectChange) this.cdr.detectChanges();
    }
  }

  removeProduct(index: number) {
    this.injectorIDs.removeAt(index);
  }

  addId(productIndex: number, event: any): void {
    const input = event.input;
    const value = event.value;
    console.log(this.injectorIDs instanceof FormArray);
    if ((value || '').trim()) {
      const ids = this.injectorIDs.at(productIndex).get('injectorID');
      console.log('IDS', ids);
      const newIds = ids?.value.concat(value.trim());
      ids?.setValue(newIds);
    }

    if (input) {
      input.value = '';
    }
  }

  removeId(productIndex: number, idIndex: number): void {
    this.injectorIDs
      .at(productIndex)
      .get('injectorID')
      ?.value.splice(idIndex, 1);
  }
  getLocationInfo(
    selectedCountries: string[],
    selectedRegions: string[],
    userType: string
  ): locationData[] {
    if (userType === plmUserRoleEnum.FSE) {
      const result = selectedRegions.map((region) => {
        if (selectedCountries[0] === 'ALL') return { region, countries: 'ALL' };
        const filteredCountries = this.countryRegionMap[region].filter(
          (country: string) => selectedCountries.includes(country)
        );

        return {
          region: region,
          countries: filteredCountries.length > 0 ? filteredCountries : [],
        };
      });

      return result.filter((item) => item.countries.length > 0);
    } else if (userType === plmUserRoleEnum.DEALER) {
      return [{ region: 'ALL', countries: selectedCountries }];
    } else {
      return [];
    }
  }
  getProductTypes(productTypes: string[], userType: string): string[] {
    if (userType === plmUserRoleEnum.BIOMED) {
      return this.injectorIDs.value.map((product: any) => product.injectorType);
    } else {
      return productTypes;
    }
  }

  saveUser(): void {
    // Add logic to save user

    //NOTE: injector ids field will always have data in it regardless of user role unless somthing is changed
    console.log(this.data.user);
    const userInfoFormGroupValue = this.userInfoFormGroup.getRawValue();
    const userProductInfoFormGroupValue =
      this.userProductInfoFormGroup.getRawValue();
    const userAssignedRoleEnumKey =
      userInfoFormGroupValue.userAssignedRole as keyof typeof plmUserRoleEnum;
    //We need to check if the key is biomed so the text comparison is correct
    const userToBesaved: any = {
      userName: userInfoFormGroupValue.userName,
      userFirstName: userInfoFormGroupValue.userFirstName,
      userMiddleName: userInfoFormGroupValue.userMiddleName || '',
      userLastName: userInfoFormGroupValue.userLastName,
      userEmailId: userInfoFormGroupValue.userEmailId,
      userOrganizationName: userInfoFormGroupValue.userOrganizationName,
      userAssignedRole: plmUserRoleEnum[userAssignedRoleEnumKey],
      userAssignedSystem: configSystemEnum.PLM,
      userLocationinfo: this.getLocationInfo(
        userInfoFormGroupValue.userCountry,
        userInfoFormGroupValue.userRegion,
        userInfoFormGroupValue.userAssignedRole
      ),
      userProductTypes: this.getProductTypes(
        userProductInfoFormGroupValue.userProductTypes,
        userInfoFormGroupValue.userAssignedRole
      ),
      userProductInjectorIDs:
        userInfoFormGroupValue.userAssignedRole === 'BIOMED'
          ? userProductInfoFormGroupValue.userProductInjectorIDs
          : undefined,
      userIsActive: userInfoFormGroupValue.userIsActive,
      userUpdatedBy: this.data.formType === 'edit' ? this.currentUserName : '',
      userCreatedBy: this.currentUserName,
      userCreatedBySystemName: configSystemEnum.PLC_APP,
      userIsDevToolsAccess: true,
    };
    console.log({ userToBesaved });
    let notificationMessage = '';
    if (this.data.formType === 'edit') {
      // Format user to be saved for edit
      const editKeys = [
        'userFirstName',
        'userMiddleName',
        'userLastName',
        'userOrganizationName',
        'userAssignedSystem',
        'userAssignedRole',
        'userLocationinfo',
        'userProductTypes',
        'userProductInjectorIDs',
        'userTokenExpiryTime',
        'userIsActive',
        'userUpdatedBy',
        'userCreatedBy',
        'userCreatedBySystemName',
        'userUpdatedDateTime',
        'userCurrentLoggedIn',
        'userLastLoginTime',
        'userLastLoginLocation',
        'userDeviceinfo',
        'userIsDevToolsAccess',
      ];
      const editedUser = Object.keys(userToBesaved)
        .filter((key) => editKeys.includes(key))
        .reduce((obj: any, key: any) => {
          obj[key] = userToBesaved[key];
          return obj;
        }, {});


      this.subs.sink = this.userService
        .updateUserProfile(this.data.user.userId, editedUser)
        .subscribe({
          next: (data) => {
            notificationMessage = 'User profile updated successfully!';
            this._snackBar.open(notificationMessage, 'Close', {
              duration: 5000,
            });
            this.subs.sink = this.userService.getPLMUserProfiles().subscribe();
            new LogHandler(this.sharedService,this.actViewerService).formatLog(`User ${this.data?.user?.userName} has been updated`, logEventEnum.INFO);

            this.modalRef.close();
          },
          error: (errorResponse) => {
            console.error(errorResponse);
            notificationMessage = errorResponse.error.message;
            new LogHandler(this.sharedService,this.actViewerService).formatLog(errorResponse?.message, logEventEnum.ERROR);
            this._snackBar.open(notificationMessage, 'Close', {
              duration: 5000,
            });
          },
        });
    }

    if (this.data.formType === 'add') {
      this.subs.sink = this.userService
        .createUserProfile(userToBesaved)
        .subscribe({
          next: (data) => {
            console.log(data);
            notificationMessage = 'User profile created successfully!';
            this._snackBar.open(notificationMessage, 'Close', {
              duration: 5000,
            });
            this.subs.sink = this.userService.getPLMUserProfiles().subscribe();
            this.actViewerService.createLogData(new LogHandler(this.sharedService,this.actViewerService).formatLog(`User ${userToBesaved.userName} has been created`, logEventEnum.INFO)).subscribe();
            this.modalRef.close();
          },
          error: (errorResponse) => {
            console.error(errorResponse);
            if (errorResponse.error.error.message instanceof Array) {
              notificationMessage =
                errorResponse.error.error.message.join(', \n');
            } else {
              notificationMessage = errorResponse.message;
            }
            new LogHandler(this.sharedService,this.actViewerService).formatLog(errorResponse?.message, logEventEnum.ERROR);

            this._snackBar.open(notificationMessage, 'Close', {
              duration: 5000,
            });
          },
        });
    }
  }
}
